.boxTitle {
    width: 100%;
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .boxTitle {
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
        padding-top: var(--size-huge);
        padding-bottom: var(--size-huge);
    }
}
@media only screen and (max-width: 992px) {
    .boxTitle {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        padding-top: var(--size-huge);
        padding-bottom: var(--size-huge);
    }
}
@media only screen and (max-width: 768px) {
    .boxTitle {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
}
@media only screen and (max-width: 576px) {
    .boxTitle {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
    }
}
