/*--FORMULARIO--*/
.sectionForm_form {
    width: 100%;
    text-align: center;
}

.sectionForm_form_texto,
.sectionForm_form_submit {
    grid-column: span 2;
}

.sectionForm_form div {
    text-align: left;
    align-items: center;
    height: auto;
    line-height: 1.6;
}

.sectionForm_form div label {
    display: block;
    color: black;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: left;
    margin: 2rem 0 1rem 0;
}
.sectionForm_form div input[type='text'],
.sectionForm_form div input[type='email'],
.sectionForm_form div input[type='subject'],
.sectionForm_form div textarea {
    background-color: white;
   border: 1px solid black;
    border-radius: 8px;
    padding: 1% 2%;
    font-family: 'Urbanist', sans-serif;
    width: 100%;
    padding: 11px 16px;
    gap: 10px;
    margin-top: 5px;
}
.sectionForm_form_submit p {
    font-size: 16px;
    font-weight: 400;
}
.sectionForm_form_submit input {
    margin-top: var(--size-medium);
    width: 100%;
    border: 1px solid var(--color-green);
    background-color: var(--color-green);
    color: black;
    padding: 1vh 2vw;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    padding: 1.5rem 0;
    cursor:pointer !important;
}

.sectionForm_form_submit input:hover {
    cursor: pointer;
}
#errorName,
#errorEmail,
#errorSubject,
#errorComment {
    color: #b40e0e;
    margin: -3rem 0 1rem 0;
    padding: 0;
    font-size: 1.2rem;
}
.invalid div input,
.invalid div input[type='email'],
.invalid div textarea {
    border: 1px solid #b40e0e !important;
    background-color: #fddddd !important;
}
input:disabled,
input:disabled:hover {
    background-color: #ccc;
    color: #292929;
    border-color: #ccc;
    cursor: auto;
}
.boxText{
    display: none;
}
.textActivo{
    display: block;
    text-align:center;
}
.textActivo p {
    color: black;
    font-weight: bold;
    font-size: 2.2rem;
    margin-top: 2rem;
    padding: 1rem 0;
}
.textPrivacyBox{
    padding: 2rem 0 0 0;
    text-align: center !important;
}
.textPrivacyBox a{
    font-size: 16px;
    color: black;
}
/*MEDIA QUERYS*/
@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {    
    font-size: 1.8rem;
}

@media only screen and (max-width: 576px) {
}
