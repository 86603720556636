.boxtext {
    display: 'inline-block';
}
.boxText_p {
    font-size: 2rem;
    line-height: 3rem;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .boxText_p {
        font-size: 1.6rem;
        line-height: 2.3rem;
    }
}
@media only screen and (max-width: 992px) {
    .boxText_p {
        font-size: 1.6rem;
        line-height: 2.3rem;
    }
}
@media only screen and (max-width: 768px) {
    .boxText_p {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 576px) {
    .boxtext {
        display: block;
        width: 100%;
    }
    .boxText_p {
        font-size: 1.3rem;
        line-height: 1.8rem;
    }
}
