.problem_box {
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge);
    height: 450px;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    background-color: var(--color-blue);
    margin-top: -1px;
}
.problem_box img {
    width: 100%;
}
.problem_box2 h2 {
    font-size: 5rem;
    font-weight: 600;
}
.problem_box2 h3 {
    font-size: 2.5rem;
    font-weight: 400;
}
.problem_box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    padding-top: var(--size-xhuge);
}
.problem_box2_float {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    padding-top: var(--size-medium);
    padding-bottom: var(--size-medium);
    margin-top: var(--size-huge);
    box-shadow: 2px 2px 8px 5px rgba(235, 223, 235, 0.8);
    position: relative;
    z-index: 999;
    background-color: var(--color-white);
}
.problem_box3 {
    display: grid;
    grid-template-columns: 3fr 2fr;
    background-color: var(--color-brownLight);
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    padding-top: var(--size-xxhuge);
    padding-bottom: var(--size-huge);
    margin-top: -160px;
}
.problem_box3_left {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: var(--size-xhuge);
}

.problem_box3_right {
    grid-column: 2;
    display: flex;
    align-items: center;
    padding-top: var(--size-medium);
    padding-bottom: var(--size-medium);
    padding-left: var(--size-huge);
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .problem_box {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
        height: 350px;
    }
    .problem_box2 {
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
        padding-top: var(--size-large);
    }
    .problem_box2_float {
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
    }
    .problem_box3 {
        grid-template-columns: 3fr 2fr;
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
        padding-top: var(--size-xxhuge);
        padding-bottom: var(--size-xhuge);
        margin-top: -160px;
    }
    .problem_box3_left {
        padding-right: var(--size-large);
    }
    .problem_box3_right {
        padding-left: 0;
        box-sizing: border-box;
        overflow: hidden;
    }
    .problem_box3_right img {
        width: 350px;
    }
}

@media only screen and (max-width: 992px) {
    .problem_box {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        height: 200px;
    }
    .problem_box2 {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        padding-top: var(--size-large);
    }
    .problem_box2 h2 {
        font-size: 4rem;
    }
    .problem_box2 h3 {
        font-size: 2rem;
    }
    .problem_box2_float {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: var(--size-medium);
    }
    .problem_box3 {
        display: flex;
        flex-direction: column;
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        padding-top: var(--size-xxhuge);
        padding-bottom: var(--size-medium);
        margin-top: -160px;
    }
    .problem_box3_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0;
    }
    .problem_box3_right {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
@media only screen and (max-width: 768px) {
    .problem_box {
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
        height: 150px;
    }
    .problem_box2 h2 {
        font-size: 3rem;
    }
    .problem_box2 h3 {
        font-size: 1.3rem;
        text-align: center;
    }
    .problem_box2_float {
        flex-direction: column;
        justify-content: space-between;
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        margin-top: var(--size-medium);
        box-shadow: 2px 2px 8px 5px rgba(235, 223, 235, 0.8);
        position: relative;
        z-index: 999;
        background-color: var(--color-white);
    }
    .problem_box3 {
        display: flex;
        flex-direction: column;
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        padding-top: 490px;
        padding-bottom: var(--size-medium);
        margin-top: -450px;
    }
    .problem_box3_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0;
    }
    .problem_box3_right {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
@media only screen and (max-width: 576px) {
    .problem_box {
        height: 100px;
    }
    .problem_box3_right img {
        width: 100%;
    }
}
