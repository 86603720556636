/*NAVEGADOR*/
.nav{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 9rem;
    display:grid;
    grid-template-columns: auto 1fr;
    grid-row: auto;
    justify-content: center;
    background-color: var(--color-black);
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
}
.nav_logo_img{
    height: 5rem;
}
.nav_language{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.nav_language img{
    height: 3rem;
    padding-left: 20px;
    cursor:pointer;
}
/*.button_language{
    background-color: transparent;
    color:white;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 1.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor:pointer;
}
.button_language img{
    padding-left: 8%;
}

.nav_MenuLanguage{
    position: absolute;
    top:70px;
    right: 0;
    z-index: 102;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1%;
    background-color: white;
}
.button_languageOption{
    width: 100%;
    text-align: left;
    border-radius: 5px;
    margin:2% 0%;
    padding: 6% 5%;
    border:none;
    background-color: white;
    cursor:pointer;
}
.button_languageOption_active{
    border: 1px solid var(--color-purple) ;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    margin:2% 0%;
    padding: 6% 5%;
    background-color: white;
    color: var(--color-purple);
    cursor:pointer;
}*/
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
       /*NAVEGADOR*/
    .nav{
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);  
        height: 9rem;         
    } 
    .nav_logo_img{
        height: 5rem;
    }
    .nav_MenuLanguage{
        top:80px;
    } 
}

@media only screen and (max-width: 1024px) {
    /*NAVEGADOR*/    
    .nav{
        padding-left: var(--size-large);
        padding-right: var(--size-large);  
        height: 8rem;      
       
    }
    .nav_logo_img{
        height: 4rem;
    } 
}
@media only screen and (max-width: 767px) {    
    /*NAVEGADOR*/
    .nav{
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        height: 7rem;
    }    
    .nav_logo_img{
        height: 3rem;
    }      
}
@media only screen and (max-width: 576px) {     
    .nav_language img{
        height: 2.5rem
    }
}
