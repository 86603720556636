.solution_boxText {
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge);
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
    text-align: center;
    background-color: var(--color-brownLight);
}
.solution_boxText h1 {
    font-size: 6.5rem;
    line-height: 8rem;
    font-weight: 400;
}
.solution_boxText h1 span {
    color: #734afe;
}
.solution_logo {
    max-width: 60rem;
}
.solution_boxSlider {
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
    text-align: center;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .solution_boxText {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .solution_boxText h1 {
        font-size: 5rem;
        line-height: 6rem;
    }
    .solution_logo {
        max-width: 50rem;
    }
    .solution_boxSlider {
        padding-top: var(--size-huge);
        padding-bottom: var(--size-huge);
    }
}

@media only screen and (max-width: 992px) {
    .solution_boxText {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .solution_boxText h1 {
        font-size: 4.5rem;
        line-height: 6rem;
    }
    .solution_logo {
        max-width: 40rem;
    }
    .solution_boxSlider {
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
}
@media only screen and (max-width: 768px) {
    .solution_boxText {
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
    }
    .solution_boxText h1 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        margin-bottom: 3rem;
    }

    .solution_logo {
        max-width: 30rem;
    }
    .solution_boxSlider {
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
    }
}
@media only screen and (max-width: 576px) {
    .solution_boxText h1 {
        font-size: 2.6rem;
        line-height: 4rem;
    }
    .solution_logo {
        max-width: 25rem;
    }
}
