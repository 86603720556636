.title {
    font-family: urbanist;
    font-weight: 900;
    text-align: center;
    font-size: 9rem;
    line-height: 11rem;
}
.subTitle {
    font-family: urbanist;
    font-weight: 600;
    text-align: center;
    font-size: 4rem;
    line-height: 40px;
}
.text {
    font-family: urbanist;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 2rem;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .title {
        font-size: 7rem;
        line-height: 9rem;
    }
    .subTitle {
        font-size: 3rem;
        line-height: 40px;
    }
}
@media only screen and (max-width: 992px) {
    .title {
        font-size: 5.5rem;
        line-height: 6.5rem;
    }
}
@media only screen and (max-width: 768px) {
    .title {
        font-size: 4rem;
        line-height: 5rem;
    }
    .subTitle {
        font-size: 2rem;
        line-height: 40px;
    }
    .text {
        margin-bottom: 18px;
    }
}
@media only screen and (max-width: 576px) {
    .title {
        font-size: 3rem;
        line-height: 4rem;
    }
    .text {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 18px;
    }
}
