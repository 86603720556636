#app {
    height: 100%;
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px !important;
}
.sliderBox{
    display:grid;
    grid-template-columns:1fr 3fr 1fr;
    grid-template-rows:600px auto;
    column-gap: 50px; 
}
.sliderBox > div:nth-child(1),
.sliderBox > div:nth-child(2),
.sliderBox > div:nth-child(3){
    box-sizing: border-box;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
.sliderBox_imgLeft{
    align-items: flex-start; 
}
.sliderBox_imgLeft >img{
   height:50%;
   max-width: 100%;
   position: relative;
   left: -20%;
}
.sliderBox_imgCenter{
    align-items: center; 
}
.sliderBox_imgCenter >img{
    max-height: 100%; 
    width: 100%;
}
.sliderBox_imgRight{
    align-items: flex-end; 
}
.sliderBox_imgRight >img{
    max-height:50%;
    max-width: 100%;
    position: relative;
    right: -30%;
 }
.sliderBox_imgCenter_text{
    grid-row: 2;
    grid-column: 2;
    padding:'5% 0';
}
.sliderBox_imgCenter_text >p{
    font-family: urbanist;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 3rem;
    padding: 3% 0;
    text-align: center;
        
}
.sliderBox_imgCenter_text >div{
    margin-bottom: 4%;
    padding:3% 0 5% 0;           
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .sliderBox{
        grid-template-rows:400px auto;
    }
}

@media only screen and (max-width: 992px) {
    .sliderBox{
        grid-template-columns:1fr 4fr 1fr;
        grid-template-rows:300px auto;
    }
    .sliderBox_imgLeft >img{
        left: -20%;
     }
     .sliderBox_imgCenter >img{
         max-height: 100%; 
         width: 100%;
     }
     .sliderBox_imgRight >img{
         right: -40%;
      }
   
    .sliderBox_imgCenter_text{
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        grid-row: 2;
        grid-column: span 3;
        padding:'2% 0';
    }
}
@media only screen and (max-width: 768px) { 
    .sliderBox_imgCenter_text{
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
    }
   
}
@media only screen and (max-width: 576px) {
    .sliderBox{
        grid-template-rows:150px auto;
    }
    .sliderBox_imgCenter_text{
        padding-right: var(--size-small);
        padding-left: var(--size-small);
    }
    .sliderBox_imgCenter_text >div{
        margin: 2% 0 10% 0 ;
        padding:3% 0 7% 0;           
    }
}
