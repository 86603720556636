/*INDEX. MODAL COOKIES*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
    background: #000;
    opacity: 0.8;
    visibility: visible;
    z-index: 1000;
}

.modalCookies {
    color: black;
    padding: 2rem 2rem;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: 0.3s ease;
    visibility: visible;
    z-index: 1500;
    opacity: 1;
    display: grid;
    grid-template-columns: 1fr auto; 
    grid-template-rows: auto;
}
.modalCookies_boxText{
    align-self: center;
    padding-right: 4rem;
}
.modalCookies_boxText p {
    font-family: 'Urbanist', sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    text-align: justify;
}
.modalCookies_boxButtons{
    text-align: right;
}
/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) { 
 
}
    
/*TABLET*/
@media only screen and (max-width: 1024px) {  
    .modalCookies {       
        grid-template-columns: 1fr; 
        grid-template-rows: auto;
    }
    .modalCookies_boxText{
        align-self: center;
        padding-right: 0;
        padding-bottom: 1rem;
    }
    .modalCookies_boxText p {
        font-size: 1.6rem;
        line-height: 1.8rem;
        text-align: justify;
    }
    .modalCookies_boxButtons{
        text-align: left;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {   
    
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {   
}
  
