/*NAVEGADOR*/
.header_section {
    background-image: url('assets/img/01_header.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge);
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr;
}
.header_logo {
    grid-column: 1;
    grid-row: 1;
}
.header_logo img {
    height: 75px;
}

.header_mainBox {
    grid-column: 1/3;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--size-large);
    padding-bottom: var(--size-large);
}
.header_mainBox h1 {
    font-size: 6rem;
    font-weight: 600;
    line-height: 8rem;
    color: var(--color-white);
}
.header_button {
    grid-column: 1/3;
    grid-row: 3;
    display: flex;
    justify-content: flex-start;
}
/*VERTICAL*/
.header_section_vertical .header_button {
    justify-content: center;
}
.nav_language {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.nav_language img {
    height: 30px;
    padding-left: 20px;
    cursor: pointer;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .header_section {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
        padding-top: var(--size-huge);
        padding-bottom: var(--size-huge);
    }
    .header_logo img {
        height: 70px;
    }
    .header_mainBox h1 {
        font-size: 5.5rem;
        line-height: 7.5rem;
    }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .header_section {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .header_logo img {
        height: 65px;
    }
    .header_mainBox h1 {
        font-size: 5rem;
        line-height: 6.3rem;
    }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 75%;
        font-size: 6.5rem;
        line-height: 8rem;
    }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
    .header_mainBox h1 {
        font-size: 4rem;
        line-height: 5rem;
    }
}
@media only screen and (max-width: 900px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 80%;
        font-size: 6rem;
        line-height: 7rem;
    }
}
@media only screen and (max-width: 767px) {
    .header_section {
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
    }
    .header_logo img {
        height: 60px;
    }
    .header_mainBox h1 {
        font-size: 4rem;
        line-height: 5rem;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 80%;
        font-size: 5.5rem;
        line-height: 7rem;
    }
}
@media only screen and (max-width: 670px) and (orientation: landscape) {
    .header_mainBox h1 {
        font-size: 3.6rem;
        line-height: 5rem;
    }
}
@media only screen and (max-width: 576px) {
    .header_logo img {
        height: 50px;
    }
    .nav_language img {
        height: 25px;
        padding-left: 20px;
    }
    .header_mainBox h1 {
        font-size: 3.4rem;
        line-height: 4.4rem;
    }
}
@media only screen and (max-width: 576px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 80%;
        font-size: 4.5rem;
        line-height: 6rem;
    }
}
@media only screen and (max-width: 460px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 85%;
        font-size: 4rem;
        line-height: 5.5rem;
    }
}
@media only screen and (max-width: 395px) and (orientation: portrait) {
    .header_logo img {
        height: 40px;
    }
    .nav_language img {
        height: 20px;
        padding-left: 20px;
    }
    .header_mainBox h1 {
        width: 85%;
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
}
@media only screen and (max-width: 300px) and (orientation: portrait) {
    .header_mainBox h1 {
        width: 85%;
        font-size: 2.8rem;
        line-height: 3.8rem;
    }
}
