.aboutUs_Box {
    display: grid;
    grid-template-columns: 4fr 3fr;
}
.aboutUs_BoxRight {
    background-image: url('assets/img/aboutUs.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.aboutUs_BoxLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge);
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
}
.aboutUs_BoxLeft > p > span {
    color: var(--color-purple);
    font-weight: bold;
}
.aboutUs_BoxLeft_logos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding-top: var(--size-large);
}
.aboutUs_BoxLeft_logos img {
    height: 35px;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .aboutUs_BoxLeft {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
    }
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 992px) {
    .aboutUs_Box {
        grid-template-columns: 1fr;
    }
    .aboutUs_BoxRight {
        width: 100%;
        height: 310px;
    }
    .aboutUs_BoxLeft {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
    }
}
@media only screen and (max-width: 768px) {
    .aboutUs_BoxLeft {
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
    }
}
@media only screen and (max-width: 576px) {
}
