.footer_section {
    background-color: var(--color-black);
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    position: relative;
    padding-top: var(--size-large);
    padding-bottom: var(--size-large);
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
}

.footer_logo {
    text-align: center;
}
.footer_logo_img {
    height: 80px;
}
.footer_nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 0.5px solid white;
    padding-top: var(--size-large);
    padding-bottom: var(--size-large);
    margin-bottom: var(--size-medium);
}
.footer_link {
    text-transform: initial;
    color: white;
    font-size: 1.8rem;
    text-decoration: none;
    padding: 1rem 2rem;
}
.footer_link:hover {
    color: var(--color-purple);
}
.footer_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer_text p {
    font-size: 1.8rem;
    font-weight: 400;
    color: #d9dbe1;
    padding: 1% 0 1% 0;
}
.footer_text_copyright {
    font-size: 1.6rem !important;
}
.footer_rrss {
    padding: 2% 0 2% 0;
    display: flex;
    flex-direction: row;
    /* display: none; */
}
.footer_rrss a {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    margin: 0 1rem;
    font-size: 2rem;
    height: 5rem;
    width: 5rem;
}
.footer_rrss a:hover {
    color: var(--color-purple);
    background-color: rgba(255, 255, 255, 0.2);
}

/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .footer_logo_img {
        height: 80px;
    }
}

/*TABLET*/
@media only screen and (max-width: 1024px) {
    .footer_section {
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
    }
    .footer_logo_img {
        height: 75px;
    }
    .footer_nav {
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
        margin-bottom: var(--size-medium);
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .footer_section {
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }
    .footer_logo_img {
        height: 75px;
    }
    .footer_nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer_link {
        font-size: 1.7rem;
        padding: 1rem 1rem;
    }
    .footer_text {
        grid-template-columns: 1fr;
    }
    .footer_copyright {
        text-align: center;
        grid-column: 1;
        grid-row: 2;
    }
    .footer_rrss {
        text-align: center;
        grid-column: 1;
        grid-row: 1;
        padding-bottom: var(--size-medium);
    }
    .footer_text p {
        font-size: 1.6rem;
        padding: 1% 0 2% 0;
    }
    .footer_text_copyright {
        font-size: 1.2rem !important;
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
    .footer_section {
        padding-top: var(--size-small);
        padding-bottom: var(--size-small);
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
    }
    .footer_logo_img {
        height: 75px;
    }
    .footer_nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer_link {
        font-size: 1.7rem;
        padding: 1rem 1rem;
    }
    .footer_text {
        grid-template-columns: 1fr;
    }
    .footer_copyright {
        text-align: center;
        grid-column: 1;
        grid-row: 2;
    }
    .footer_rrss {
        text-align: center;
        grid-column: 1;
        grid-row: 1;
        padding-bottom: var(--size-medium);
    }
    .footer_text p {
        font-size: 1.6rem;
        padding: 1% 0 2% 0;
        text-align: center;
        line-height: 3rem;
    }
    .style.footer_text_copyright {
        font-size: 0.8rem;
    }
}
