.download_section{
    background: linear-gradient(90.12deg, #00E798 0.1%, #7A48FF 99.89%);
    text-align: center;    
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge); 
    padding-top: var(--size-huge); 
    padding-bottom: var(--size-huge);  
}
.download_section h3{
    font-size: 5rem;
    padding-bottom: var(--size-large);
    font-family: 'Urbanist', sans-serif;
}
.download_section p{
    font-weight: 600 !important;
    padding-left: var(--size-xxhuge) !important;
    padding-right: var(--size-xxhuge) !important;
}
.download_section div img{
    height: 90px;
    padding: 1%;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .download_section{  
        padding-right: var(--size-huge);
        padding-left: var(--size-huge); 
        padding-top: var(--size-huge); 
        padding-bottom: var(--size-huge);  
    }
    .download_section div img{
        height: 80px;
    }
}
@media only screen and (max-width: 1024px) { 
    .download_section div img{
        height: 75px;
    }
}
@media only screen and (max-width: 992px) {
    .download_section{ 
        padding-right: var(--size-large);
        padding-left: var(--size-large); 
        padding-top: var(--size-large); 
        padding-bottom: var(--size-large);  
    }
    .download_section h3{
        font-size: 4rem;
        padding-bottom: var(--size-medium);
    }
    .download_section p{
        padding-left:0 !important;
        padding-right: 0 !important;
    }
    .download_section div img{
        height: 60px;
    }
}
@media only screen and (max-width: 768px) {
    .download_section{
        text-align: center;    
        padding-right: var(--size-medium);
        padding-left: var(--size-medium); 
        padding-top: var(--size-medium); 
        padding-bottom: var(--size-medium);  
    }
    .download_section h3{
        font-size: 3rem;
        padding-bottom: var(--size-medium);
    }
    .download_section div img{
        height: 60px;
    }
    
}
@media only screen and (max-width: 576px) {
    .download_section h3{
        font-size: 2rem;
        padding-bottom: var(--size-medium);
    }
    .download_section div img{
        height: 55px;
    }
    .download_section p{
        font-size: 1.6rem !important;
    }
}
