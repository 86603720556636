.blockchain_box {
    background-image: url('assets/img/blockchain_back.png');
    background-repeat: no-repeat;
    background-size: auto 130%;
    background-position: right;
    padding-right: var(--size-xhuge);
    padding-left: var(--size-xhuge);
    padding-top: var(--size-xhuge);
    padding-bottom: var(--size-xhuge);
    position: relative;
}
.blockchain_box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, transparent 0%, white 60%);
    z-index: 1;
}
.blockchain_box > div {
    position: relative;
    z-index: 2;
    width: 45%;
}
.blockchain_box > div > p > span {
    color: var(--color-purple);
}
.blockchain_box > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blockchain_box > div > div > p {
    background-color: #81e7c3;
    padding: 0.7rem 4rem;
    margin-top: 1rem;
    font-size: 2.6rem;
}
.blockchain_box > div > div > p > span {
    color: var(--color-purple);
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .blockchain_box {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
        padding-top: var(--size-huge);
        padding-bottom: var(--size-huge);
        background-size: 85%;
    }
    .blockchain_box > div {
        width: 50%;
    }
    .blockchain_box > div > div > p {
        padding: 0.7rem 2rem;
        margin-top: 1rem;
        font-size: 2rem;
    }
}

@media only screen and (max-width: 992px) {
    .blockchain_box {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
        background-size: 95%;
        background-position: right;
    }
    .blockchain_box > div {
        width: 60%;
    }
}
@media only screen and (max-width: 768px) {
    .blockchain_box {
        padding-right: var(--size-large);
        padding-left: var(--size-large);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
        background-image: none;
    }
    .blockchain_box > div {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .blockchain_box > div > p {
        text-align: center !important;
    }
}
@media only screen and (max-width: 576px) {
}
