.contact_section {
    position: relative;
}
.contact_Box {
    display: grid;
    grid-template-columns: 4fr 3fr;
}
.contact_mainBox {
    background-image: url('assets/img/contact_back.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-right: 30%;
    padding-left: 30%;
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact_mainBox h3 {
    font-size: 4rem;
    padding-bottom: var(--size-large);
    font-weight: 500;
}
.imgPlaneTop {
    width: 100px;
    position: absolute;
    left: 12%;
    top: 40%;
}
.imgPlaneDown {
    width: 130px;
    position: absolute;
    right: 12%;
    bottom: 20%;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .contact_mainBox {
        padding-right: 25%;
        padding-left: 25%;
    }
    .contact_mainBox h3 {
        font-size: 3.8rem;
        padding-bottom: var(--size-large);
    }
    .imgPlaneTop {
        width: 88px;
        left: 8%;
        top: 45%;
    }
    .imgPlaneDown {
        width: 110px;
        right: 8%;
        bottom: 15%;
    }
}
@media only screen and (max-width: 1024px) {
    .imgPlaneTop {
        width: 45px;
        left: 2%;
        top: 45%;
    }
    .imgPlaneDown {
        width: 60px;
        right: 2%;
        bottom: 15%;
    }
}
@media only screen and (max-width: 992px) {
    .contact_mainBox {
        padding-right: 15%;
        padding-left: 15%;
        padding-top: var(--size-medium);
        padding-bottom: var(--size-large);
    }
    .contact_mainBox h3 {
        font-size: 3.6rem;
        padding-bottom: var(--size-large);
    }
}
@media only screen and (max-width: 768px) {
    .contact_mainBox {
        padding-right: var(--size-medium);
        padding-left: var(--size-medium);
        align-items: start;
    }
    .contact_mainBox h3 {
        font-size: 2.4rem;
        padding-bottom: var(--size-small);
    }
    .imgPlaneTop,
    .imgPlaneDown {
        display: none;
    }
}
@media only screen and (max-width: 576px) {
}
