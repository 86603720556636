.button {
    color: #2b383b;
    border-radius: 6px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-style: normal;
    align-self: center;
    background-color: var(--color-green);
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 7rem;
    padding-right: 7rem;
    font-size: 2rem;
}
.buttonCookies {
    margin-left: 1rem;
}
.button:hover {
    background-color: #07ab72;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .button {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 7rem;
        padding-right: 7rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 992px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 5rem;
        padding-right: 5rem;
        font-size: 2rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 768px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 5rem;
        padding-right: 5rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 576px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 350px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }
}
