#root {
    white-space: pre-line;
}
:root {
    --color-gray: #e5e5e5;
    --color-brownLight: #eceae4;
    --color-white: white;
    --color-black: #000;
    --color-purple: #734afe;
    --color-green: #00e798;
    --color-blue: #0077ff;
    --color-yellow: #ffd932;

    --size-none: 0;
    --size-xtiny: 4px;
    --size-tiny: 8px;
    --size-mini: 12px;
    --size-small: 16px;
    --size-base: 20px;
    --size-medium: 24px;
    --size-large: 32px;
    --size-xlarge: 48px;
    --size-huge: 64px;
    --size-xhuge: 128px;
    --size-xxhuge: 192px;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.6rem;
}

p {
    font-size: 1.3rem;
}

li {
    font-size: 1.3rem;
}

* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
body {
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 100vw;
}
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.6rem;
    font-family: 'Urbanist', sans-serif !important;
}
